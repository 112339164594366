import React from "react";
import Layout from "../components/layout";
import { graphql, useStaticQuery } from "gatsby";
import { Link } from "gatsby";
import eventStyles from "./events.module.scss";
import Content from "../components/content";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faCalendar } from '@fortawesome/free-solid-svg-icons';


const EventsPage = () => {

  const data = useStaticQuery (graphql`
    query {
        allContentfulEvents (sort: { fields: date, order: DESC}){
            edges{
                node {
                   eventName
                   slug
                   date(formatString:"MMMM DD, YYYY")
                   eventImage {
                     file {
                       url
                       fileName
                     }
                   }
                }
            }
        }
    }
    `)

  return (
    <Layout>
      <Content>
        <h1 className={eventStyles.title}>Next events</h1>
        <div className={eventStyles.container}>
            <div>
                <ol className={eventStyles.cards}>
                    {data.allContentfulEvents.edges.map((edge) => {
                        return (
                            <li className={eventStyles.cardItem}>
                                <div className={eventStyles.card}>
                                    <Link className={eventStyles.link} to={`/event/${edge.node.slug}`}>
                                        <div className={eventStyles.postImage}>
                                            <img className={eventStyles.image} src={edge.node.eventImage.file.url} alt={edge.node.eventImage.file.fileName} />
                                        </div>
                                        <div className={eventStyles.postHeader}>
                                          <div className={eventStyles.postInfo}>
                                              <h2>{edge.node.eventName}</h2>
                                              <p><FontAwesomeIcon icon={faCalendar} /> {edge.node.date}</p>
                                          </div>
                                            <button className={eventStyles.join}>Join!</button>
                                        </div>
                                    </Link>
                                </div>
                            </li>
                        )
                    })}
                </ol>
            </div>
        </div>
      </Content>
    </Layout>
  )
}

export default EventsPage;